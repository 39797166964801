import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"
import "../components/singlepage.scss"

const AboutPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo, Psicoterapeuta cognitivo-comportamentale | Gardone Val Trompia"
        description="Dott. Stefano Maffina - Psicologo Psicoterapeuta cognitivo-comportamentale a Gardone Val Trompia, BS"
      />
      <div className="fix-content-width">
        <NavBar />
        <div className="singlepage-container">
          <div className="section-subtitle singlepage-title">
            <h2
              data-aos="custom-fade"
              data-aos-duration="1000"
              style={{ color: "#121212" }}
            >
              <span style={{ fontWeight: 700, textDecoration: "italic" }}>
                Chi sono
              </span>
            </h2>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="singlepage-title-border"
            ></div>
            <div className="fix-content-width">
              <div className="paddings-lr">
                <div className="about-flex-fix">
                  <div
                    data-aos="custom-fade"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                    className="rectangle img-one"
                  ></div>
                  <div className="about-text-fix">
                    <p
                      data-aos="custom-fade"
                      data-aos-duration="1000"
                      data-aos-offset="100"
                      className="text-sm-dark about-text"
                    >
                      Stefano Maffina, ho conseguito la laurea magistrale nel
                      2016 presso l’Università Cattolica del Sacro Cuore di
                      Brescia.
                    </p>
                    <p
                      data-aos="custom-fade"
                      data-aos-duration="1000"
                      className="text-sm-dark about-text"
                    >
                      Nel 2018 mi sono abilitato all'esercizio della professione
                      di psicologo presso l'Ordine degli psicologi della
                      Lombardia (n. 20469),
                    </p>
                    <p
                      data-aos="custom-fade"
                      data-aos-duration="1000"
                      className="text-sm-dark about-text"
                    >
                      Mi sono specializzato come Psicoterapeuta cognitivo
                      comportamentale presso la scuola “AcaBS-Accademy of
                      behavioural science” di Brescia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </Layout>
  )
}

export default AboutPage
